<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout white wrap ma-2>
      <material-UserStoryForm
        :loading="loading"
        :user-story="userStory"
        @save="updateUserStory($event)"
      />
      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>

<script>
// Utilities
import SnackBarMixin from '@/mixins/SnackBarMixin';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'UserStoryEditForm',

  // components:{Snackbar},
  mixins: [SnackBarMixin],

  data: () => ({
    loading: true,
    userStory: {},
  }),

  created() {
    this.showLoader();
    this.get_user_stories_by_id(this.$route.params.id)
      .then((response) => {
        console.log('User Stories OK', response);
        this.userStory = response;
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Obtention de la User Story impossible : ' + error.message,
        });
      })
      .finally(() => {
        this.showLoader();
        this.loading = false;
      });
  },

  methods: {
    ...mapActions('userStory', [
      'get_user_stories_by_id',
      'update_user_stories',
    ]),
    ...mapMutations('app', ['showLoader']),

    updateUserStory(userStories) {
      this.showLoader();
      this.update_user_stories(userStories)
        .then((response) => {
          console.log('EDIT', response);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text:
              'Edition de la User Story#' +
              response.id +
              ' "' +
              response.name +
              '" effectué avec succès',
          });
        })
        .catch((error) => {
          console.log('Erreur', error);
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Mise à jour de la User Story impossible : ' + error.message,
          });
        })
        .finally(() => {
          this.showLoader();
        });
    },
  },
};
</script>
